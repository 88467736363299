/**
/** Color Palette
/**

$black: #000000
$gray: #BDBDBD // "Gray 4" in Figma
$overlay: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)
$full-overlay: rgba(0, 0, 0, 0.6)
$green: #27AA5C
$green30: #BDE5CC
$green20: #DAEDDD
$orange: #F76E3F
$orange30: rgba(247, 110, 63, 0.3)
$pink: #EBD0FF
$pink50: #F5E8FD
$pink20: #FAF5FD
$yellow: #FFFFD3
$yellow30: #FFFFF2
$tan: #E4E4D9
$white60: rgba(255, 255, 255, 0.6)
$white40: rgba(255, 255, 255, 0.4)
$white: #ffffff

.color-black
	color: $black

.color-overlay
	color: $overlay

.color-green
	color: $green

.color-green30
	color: $green30

.color-green20
	color: $green20

.color-orange
	color: $orange

.color-pink
	color: $pink

.color-pink50
	color: $pink50

.color-pink20
	color: $pink20

.color-yellow
	color: $yellow

.color-yellow30
	color: $yellow30

.color-tan
	color: $tan

.color-white40
	color: $white40

.color-white
	color: $white
