/**
/** Resets
/**
html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5,
h6, p, blockquote, pre,a, abbr, acronym, address, big,
cite, code,del, dfn, em, img, ins, kbd, q, s, samp,small,
strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt,
dd, ol, ul, li,fieldset, form, label, legend,table, caption,
tbody, tfoot, thead, tr, th, td,article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary,time, mark, audio, video, input,option
	font-family: 'DM Sans', sans-serif
	margin: 0
	padding: 0
	border: 0
	vertical-align: baseline
html
	-ms-text-size-adjust: 100%
	-webkit-text-size-adjust: 100%
img,fieldset, a img
	border: none
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea
	-webkit-appearance: none

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-selected
	border: none !important
	background-color: inherit !important
	-webkit-text-fill-color: inherit !important
	-webkit-box-shadow: none !important

input[type="submit"],
button
	cursor: pointer
	&::-moz-focus-inner
		padding: 0
		border: 0
button
	background: none
textarea
	overflow: auto
input, button
	margin: 0
	padding: 0
	border: 0

div, input, textarea, select,button,
h1,h2,h3,h4,h5,h6,a,span,a:focus
	outline: none
ul,ol
	list-style-type: none
table
	border-spacing: 0
	border-collapse: collapse
	width: 100%
html
	box-sizing: border-box
*, *:before, *:after
	box-sizing: inherit

@media (prefers-reduced-motion: reduce)
	*
		transition: none !important

a
	text-decoration: none

svg,
img
	vertical-align: middle
