.shadow-xxsmall
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05)

.shadow-xsmall
	box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)

.shadow-small
	box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06)

.shadow-medium
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)

.shadow-large
	box-shadow: 0 20px 24px -4px rgba(0, 0, 0, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03)

.shadow-xlarge
	box-shadow: 0 24px 48px -12px rgba(16, 24, 40, 0.18)

.shadow-xxlarge
	box-shadow: 0 32px 64px -12px rgba(16, 24, 40, 0.14)
