@import "_colors.module"
@import "_icons-variables.module"
/**
/** UI Elements
/**

// Buttons
=with-icon
	grid-column-gap: 0.75rem
	i
		display: inline

=button-padding-small
	padding: 0.75rem 1rem

=button-padding-regular
	padding: 1rem 3.5rem

=button-common
	font-family: VG5000, sans-serif
	line-height: 1.2
	text-align: center
	display: flex
	flex-direction: row
	justify-content: center
	align-items: center
	border-radius: 0.5rem
	+button-padding-regular
	font-size: 1rem
	vertical-align: middle
	letter-spacing: 0.05em
	&.with-icon
		+with-icon
	&.full-width
		width: 100%
	>p,>span
		line-height: 1.2
		font-family: VG5000, sans-serif
		text-align: center
		font-size: 1rem
		margin-bottom: -0.1rem
	&:hover
		cursor: pointer
		filter: brightness(0.9)
	&.is-button-small
		+button-padding-small

=button
	+button-common
	background-color: $black
	color: $white
	border: solid 1px $black
	&.is-alternate
		background-color: $white
		color: $black
	&.is-button-small
		padding: 0.5rem 1.25rem

=button-3d
	+button-common
	color: $white
	background: $black
	border: 1px solid $black
	box-sizing: border-box
	box-shadow: $white 5px 6px, $black 5px 6px 0px 1px
	margin-bottom: spacing(1)
	&:active
		box-shadow: none
		transform: translate(8px,8px)
		transition: all 0.1s linear
	&:disabled
		background: $gray
		border-color: $gray
		box-shadow: $white 5px 6px, $gray 5px 6px 0px 1px

=button-secondary
	+button-common
	padding: 1rem 3.5rem
	border-style: solid
	border-width: 1px
	border-color: $black
	border-radius: 0.5rem
	background-color: $white
	color: $black
	text-align: center
	&.is-button-small
		padding: 0.5rem 1.25rem
	&.is-alternate
		border-color: $white
		background-color: $black
		color: $white


=button-tertiary
	+button-common
	border-radius: 0
	padding: 0.75rem 1.5rem
	border: 1px solid transparent
	background-color: #f4f4f4
	color: $black
	&.is-button-small
		padding: 0.5rem 1.25rem
	&.is-alternate
		border-color: #464646
		background-color: #464646
		color: $white

=button-link
	+button-common
	padding: 0.25rem 0rem
	background-color: transparent
	color: $black
	line-height: 1
	text-decoration: none
	&.is-alternate
		color: $white

// Form Elements
=field-wrapper
	display: flex
	flex-direction: column
	align-items: flex-start
	width: stretch
	position: relative

=field-label
	margin-bottom: 0.5rem
	font-weight: 400

=form-input
	font-family: 'DM Sans', sans-serif
	height: auto
	min-height: auto
	margin-bottom: 0px
	padding: 1.125rem 0.75rem 1.125rem 1.25rem
	border-style: solid
	border-width: 1px
	border-color: $black
	border-radius: 0.75rem
	background-color: $white
	color: $black
	font-size: 1rem
	line-height: 1.3
	width: stretch
	&:focus
		border-color: $black
	&::-webkit-input-placeholder
		color: $black
	&:-ms-input-placeholder
		color: $black
	&::-ms-input-placeholder
		color: $black
	&::placeholder
		color: $black
	&.is-select
		background-color: $white
		background-image: none
	&.is-text-area
		overflow: auto
		height: auto
		min-height: 15.375rem
		padding-top: 0.75rem
		padding-bottom: 0.75rem
	&.is-select-input
		background-color: $white
		background-image: none

=form-checkbox
	display: flex
	margin-bottom: 0px
	padding-left: 1.25rem
	align-items: center
	margin-right: 1rem
	input
		visibility: visible
		-webkit-appearance: none
		width: 1.125rem
		height: 1.125rem
		min-height: 1.125rem
		min-width: 1.125rem
		margin-top: 0px
		margin-right: 0.5rem
		margin-left: -1.25rem
		border-style: solid
		border-width: 1px
		border-color: #000
		border-radius: 0px
		transition: all 200ms ease
		cursor: pointer
		&:checked
			background-color: $black
			&:before
				font-family: 'trend-icons' !important
				font-size: 1rem
				content: $icon-check-filled
				color: $white

=form-radio
	display: flex
	margin-bottom: 0px
	padding-left: 1.125rem
	align-items: center
	margin-right: 1rem
	input
		appearance: none
		position: relative
		width: 1.125rem
		height: 1.125rem
		min-height: 1.125rem
		min-width: 1.125rem
		margin-top: 0px
		margin-right: 0.5rem
		margin-left: -1.125rem
		border-style: solid
		border-width: 1px
		border-color: $black
		border-radius: 100px
		cursor: pointer
		&:checked
			background-color: $white
			border-width: 5px