@import "_breakpoints.module"
/* 
/* Typography
/* 
// Fonts
@import url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/VG5000/font.css')
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap')

// HTML Heading Elements
=header
	font-family: VG5000, sans-serif
	color: #000
	margin-top: 0rem
	margin-bottom: 0rem

=h1
	line-height: 1.17
	font-weight: 400
	font-size: 2.25rem
	+sm
		font-size: 2.5rem
	+md
		font-size: 3.25rem
	+lg
		font-size: 3.75rem

=h2
	line-height: 1.17
	font-weight: 400
	font-size: 2.25rem
	+md
		font-size: 2.75rem
	+lg
		font-size: 3.33rem

=h3
	line-height: 1.2
	font-weight: 400
	font-size: 2rem
	+md
		font-size: 2.25rem
	+lg
		font-size: 2.5rem

=h4
	font-weight: 400
	font-size: 1.5rem
	line-height: 1.4
	+md
		font-size: 1.75rem
		line-height: 1.3
	+lg
		font-size: 2rem

=h5
	line-height: 1.4
	font-weight: 400
	font-size: 1.25rem
	+md
		font-size: 1.5rem

=h6
	line-height: 1.4
	font-weight: 400
	font-size: 1.125rem
	+md
		font-size: 1.25rem

// Font Classes
=VG5000
	font-family: VG5000, sans-serif

=DM-Sans
	font-family: 'DM Sans', sans-serif

// Heading Classes
=heading-xxlarge
	line-height: 1.2
	font-weight: 700
	font-size: 2.5rem
	+md
		font-size: 3.25rem
	+lg
		font-size: 3.5rem

=heading-xlarge
	line-height: 1.2
	font-weight: 700
	font-size: 2.25rem
	+md
		font-size: 2.75rem
	+lg
		font-size: 3rem

=heading-large
	line-height: 1.2
	font-weight: 700
	font-size: 2rem
	+md
		font-size: 2.25rem
	+lg
		font-size: 2.5rem

=heading-medium
	font-weight: 700
	font-size: 1.5rem
	line-height: 1.4
	+md
		font-size: 1.75rem
	+lg
		font-size: 2rem
		line-height: 1.3

=heading-small
	line-height: 1.4
	font-weight: 700
	font-size: 1.25rem
	+md
		font-size: 1.5rem

=heading-xsmall
	line-height: 1.4
	font-weight: 700
	font-size: 1.125rem
	+md
		font-size: 1.25rem


// Other HTML Tags
=p
	margin-bottom: 0rem
	font-family: 'DM Sans', sans-serif
	font-size: 1.25rem
	line-height: 1.6

=a
	border-color: #000
	font-family: VG5000, sans-serif
	color: #000
	line-height: 1.2
	text-decoration: underline
	&:hover
		cursor: pointer

=ul
	font-family: 'DM Sans', sans-serif
	list-style-type: disc
	margin-top: 1rem
	margin-bottom: 1rem
	padding-left: 1.25rem

=ol
	font-family: 'DM Sans', sans-serif
	list-style-type: decimal
	margin-top: 1rem
	margin-bottom: 1rem
	padding-left: 1.5rem

=li
	font-family: 'DM Sans', sans-serif
	margin-top: 0.25rem
	margin-bottom: 0.25rem
	padding-left: 0.5rem

=blockquote
	margin-bottom: 0rem
	padding: 0.75rem 1.25rem
	border-left: 0.1875rem solid #000
	font-size: 1.25rem
	line-height: 1.5


// Text Sizes
=text-size-large
	font-size: 1.125rem
	+md
		font-size: 1.25rem

=text-size-medium
	font-size: 1rem
	+md
		font-size: 1.125rem

=text-size-regular
	font-size: 1rem

=text-size-small
	font-size: 0.875rem

=text-size-tiny
	font-size: 0.75rem

// Text Weights
=text-weight-xbold
	font-weight: 800

=text-weight-bold
	font-weight: 700

=text-weight-semibold
	font-weight: 600

=text-weight-medium
	font-weight: 500

=text-weight-normal
	font-weight: 400

=text-weight-light
	font-weight: 300

// Text Letter Spacings
=text-letterspacing-xwide
	letter-spacing: 0.05em

=text-letterspacing-wide
	letter-spacing: 0.025em

=text-letterspacing-normal
	letter-spacing: 0em

=text-letterspacing-tight
	letter-spacing: -0.015em

=text-letterspacing-xtight
	letter-spacing: -0.03em

// Text Line Heights
=text-lineheight-xtall
	line-height: 2

=text-lineheight-tall
	line-height: 1.7

=text-lineheight-normal
	line-height: 1.5

=text-lineheight-short
	line-height: 1.3

=text-lineheight-xshort
	line-height: 1.2

=text-lineheight-none
	line-height: 1


// Text Styles
=text-style-italic
	font-style: italic

=text-style-strikethrough
	text-decoration: line-through

=text-style-allcaps
	text-transform: uppercase

=text-style-nowrap
	white-space: nowrap

=text-style-quote
	margin-bottom: 0rem
	padding: 0.75rem 1.25rem
	border-left: 0.1875rem solid #000
	font-size: 1.25rem
	line-height: 1.5

=text-style-link
	text-decoration: underline

=text-style-2lines
	display: -webkit-box
	overflow: hidden
	-webkit-line-clamp: 2
	-webkit-box-orient: vertical

=text-style-3lines
	display: -webkit-box
	overflow: hidden
	-webkit-line-clamp: 3
	-webkit-box-orient: vertical

=text-style-muted
	opacity: 0.6

// Text Alignment
=text-align-left
	text-align: left

=text-align-center
	text-align: center

=text-align-right
	text-align: right
